import React from "react"
import Layout from "../../component/layout";
import Meta from "../../component/meta"

export default ({location}) => 
<React.Fragment>
<Meta
  title="Roman Guivan: About me"
  lang="en"
  keywords={[]}></Meta>
<Layout language={ location.pathname.split('/')[1] }>
<h1>About me</h1>
<p>Roman, {new Date().getFullYear() - 1993}, love <a href="https://en.wikipedia.org/wiki/Tony_Hawk%27s_Pro_Skater_3">Pro Skater 3</a>, <a href="http://www.deftones.com/">Deftones</a>, comic books and battle rap.</p>    
<h2>Social networks section</h2>
<ul>
    <li> <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/roman.guivan">Facebook, if you wanna be friends</a>  </li>
    <li> <a target="_blank" rel="noopener noreferrer" href="https://stackoverflow.com/users/4091861/%D0%A0%D0%BE%D0%BC%D0%B0%D0%BD-%D0%93%D1%83%D0%B9%D0%B2%D0%B0%D0%BD">Stackoverflow</a>  </li>
    <li> <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/pisjun_pisjun">my weird instagram</a>  </li>
    <li> <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1gxe_4CFtxIE2fxwOysoPrXaC95Q2k2Kr7muPHMWINoo/edit?usp=sharingn">my comic book backlog!!</a>  </li>
    <li> <a target="_blank" rel="noopener noreferrer" href={"/"+location.pathname.split('/')[1] + "/games"}>Games i've played statistics and MY THOUGHTS on them</a>  </li>
</ul>
<p>Eventually I'll expand the page with a cv download link, my favorite movies list etc</p>
</Layout>
</React.Fragment>