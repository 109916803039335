import React from "react"
import styles from "./layout.module.css"
import Header from './header';
// import FbCommentsBox from "./fb-comments"
export default function Layout ({ children, language, comments, url }) {
  let layout = (
    <div className={styles.layout}> 
        <Header lang={language || "ru"}></Header>
        <section>{children}</section>
        {/* <FbCommentsBox url={url} enabled={comments}></FbCommentsBox> */}
    </div>
  );
  return layout;
}