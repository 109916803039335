import React from 'react'
import Helmet from 'react-helmet'

function Meta({ lang, keywords, title, url }) {
    return (
          <Helmet
            meta={[
              {
                name: "language",
                content: lang || "en"
              },
              {
                name: "keywords",
                content: (keywords || []).join(', ')
              },
              {
                name: "og:url",
                content: url || "http://roman-guivan.online"
              },
              {
                name: "og:title",
                content: title
              },
              {
                name: "og:description",
                content: (keywords || []).join(',')
              },
              {
                name: "fb:app_id",
                content: "1135409286614400"
              }
            ]}
            title={title}
          >
            <html lang={lang} />
          </Helmet>
    )
  }
    
  export default Meta