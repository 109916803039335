import React from "react"
import styles from "./header.module.css"
import { StaticQuery, Link, graphql } from "gatsby"

export default ({lang}) => (
    <StaticQuery
        query={graphql`
            query {
              site {
                siteMetadata {
                  title {
                      ru,
                      en
                  }
                }
              }
            }
            `}
        render={
            (data) => {
            lang = lang || "en";
            return (
                <header className={styles.header}> 
                    <Link className={styles.home} to={ `/${lang}/${lang}-index` }> <span>{data.site.siteMetadata.title[lang]}</span></Link>
                </header>
            )}
        } 
    />
)